import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Col, Container, Row } from "react-bootstrap";

gsap.registerPlugin(ScrollTrigger);

function Section4() {
  const ref = useRef<any>();
  const imgRef = useRef(null);
  const textRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      imgRef.current,
      { y: 200, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        ease: "power3.out",
        scrollTrigger: {
          trigger: imgRef.current,
          start: "top 100%",
          end: "top 20%",
          scrub: 2,
          markers: false,
        },
      }
    );
    gsap.fromTo(
      textRef.current,
      { scale: 1.5, opacity: 0 }, // Start with larger scale and hidden
      {
        scale: 1, // End with normal scale
        opacity: 1, // Fully visible
        scrollTrigger: {
          trigger: textRef.current,
          start: "top 70%", // Trigger animation when element is 80% from the top
          end: "top 30%", // End animation when element is 30% from the top
          scrub: true, // Sync animation with scroll position
        },
        ease: "power3.out", // Animation easing
        duration: 1.5, // Duration of the animation
      }
    );

    gsap.fromTo(
      buttonRef.current,
      { x: "-100%", opacity: 0 }, // Start 30% to the left with opacity 0
      {
        x: "0%", // End at the original position
        opacity: 1, // Fully visible
        duration: 1.5, // Duration of the animation
        ease: "power3.out", // Smooth easing
        scrollTrigger: {
          trigger: buttonRef.current,
          start: "top 100%", // Trigger animation when element is 70% from the top
          end: "top 70%", // End when the top of the button is 50% from the top of the viewport
          scrub: 1, // Smooth animation synced with scroll
        },
      }
    );
  }, []);

  let storeData = [
    {
      img: "/neck-lace.png",
      header: "Halo No.1 – a full set ring.",
      desc: "40 beautiful, colourless, triple excellent cut diamonds set in a slim band of solid gold.",
    },
    {
      img: "/mothiram.png",
      header: "Halo No.1 – a full set ring.",
      desc: "40 beautiful, colourless, triple excellent cut diamonds set in a slim band of solid gold.",
    },
    {
      img: "/kammal.png",
      header: "Halo No.1 – a full set ring.",
      desc: "40 beautiful, colourless, triple excellent cut diamonds set in a slim band of solid gold.",
    },
    {
      img: "/vala.png",
      header: "Halo No.1 – a full set ring.",
      desc: "40 beautiful, colourless, triple excellent cut diamonds set in a slim band of solid gold.",
    },
  ];
  return (
    <section className="section-4 ">
      <video
        loop
        muted
        autoPlay={true}
        playsInline
        className="section-4-video full-width-video align-bottom"
      >
        <source src="/section4bgvideo-vmake.mp4" type="video/mp4" />
      </video>
      <div className="section4-bg">
        <div className="heading-textt">
          <h3> Purchase our Collection </h3>
        </div>
        <br />
        <Container>
          <Row className="content-sub-divs-Desk g-0 ">
            {storeData.map((item: any) => (
              <Col xs={12} sm={3} lg={3}>
                <div className="content-sub-divs">
                  <img src={item?.img} alt="Image1" />
                  <div className="content-txt1">{item.header}</div>
                  <div className="content-txt2">{item.desc}</div>
                </div>
              </Col>
            ))}
          </Row>
          <Row className="content-sub-divs-Mob ">
            <div className="Horizontal_PscrollBox" ref={ref}>
              {storeData?.map((item: any) => {
                return (
                  <Col xs={11} sm={6}>
                    <div
                      className="Horizontal_item"
                      style={{ backgroundImage: `url(${item?.img})` }}
                    >
                      <div className="content-txt1">{item.header}</div>
                      <div className="content-txt2">{item.desc}</div>
                    </div>
                  </Col>
                );
              })}
            </div>
          </Row>
        </Container>
        <div className="section4-btn">
          <a href="https://store.luckystargold.com/" target="_blank">
            <button className="defult-btn " ref={buttonRef}>
              Vist Our Store{" "}
            </button>
          </a>
        </div>
      </div>
    </section>
  );
}

export default Section4;
