import { useEffect, useState } from "react";
import "./style.scss";

function Header() {
  const [isHidden, setIsHidden] = useState(false);
  const [isScrolled, setScrolled] = useState<boolean>(false);
  const [isNavbarOpen, setNavbarOpen] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    require("bootstrap/dist/js/bootstrap.bundle.min.js");
  }, []);

  const handleLinkClick = () => {
    if (window.innerWidth < 992) {
      setNavbarOpen(false);
    }
  };
  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      if (window.scrollY === 0) {
        setIsHidden(false);
      } else if (window.scrollY > lastScrollY) {
        setIsHidden(true);
      }
      lastScrollY = window.scrollY;
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      id="navebarBox"
      className={`navbar navbar-expand-lg pt-lg-4 fixed-top px-lg-5 ${
        isHidden ? "hidden" : ""
      }`}
    >
      <div className="container-fluid px-lg-5 d-flex align-items-center">
        <div
          className="navbar-brand me-3 "
          style={{ maxWidth: "150px", width: "100%" }} // Responsive max-width for the logo
        >
          <img src="/applogo.png" className="img-fluid" alt="Logo" />
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded={isNavbarOpen}
          aria-label="Toggle navigation"
          style={{ color: "white", borderColor: "white" }}
          onClick={() => setNavbarOpen(!isNavbarOpen)}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={`toggleBar-${
            isNavbarOpen ? "show" : ""
          } collapse navbar-collapse ${isNavbarOpen ? "show" : ""}`}
          id="navbarNav"
        >
          <ul className="navbar-nav ms-auto d-flex gap-2 gap-lg-4">
            <li className="nav-item">
              <a
                className="nav-link head-text-color px-1 px-lg-3 small text-nowrap"
                href="https://store.luckystargold.com/"
                target="_blank"
                onClick={handleLinkClick}
              >
                Gold Store
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link head-text-color px-1 px-lg-3 small text-nowrap"
                href=""
                target="_blank"
                onClick={handleLinkClick}
              >
                Customize
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link head-text-color px-1 px-lg-3 small text-nowrap"
                aria-current="page"
                href="https://customize.luckystargold.com/"
                target="_blank"
                onClick={handleLinkClick}
              >
                Upload your design
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link head-text-color px-1 px-lg-3 small text-nowrap"
                href="https://digigold.luckystargold.com/"
                target="_blank"
                onClick={handleLinkClick}
              >
                Digital Gold
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link head-text-color px-1 px-lg-3 small text-nowrap"
                href="https://auction.luckystargold.com/"
                target="_blank"
                onClick={handleLinkClick}
              >
                Auction
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link head-text-color px-1 px-lg-3 small text-nowrap"
                href="https://store.luckystargold.com/Contactus"
                target="_blank"
                onClick={handleLinkClick}
              >
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Header;
