import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./style.scss";
gsap.registerPlugin(ScrollTrigger);
function Section2() {
  const imgRef = useRef(null);
  const buttonRef = useRef(null);
  const textRef = useRef(null);
  const titleRef = useRef(null);
  const paragraphRef = useRef(null);
  const imageRef = useRef(null);
  useEffect(() => {
    gsap.fromTo(
      imgRef.current,
      { y: 200, opacity: 0 },
      {
        y: 0,
        opacity: 1,
        ease: "power3.out",
        scrollTrigger: {
          trigger: imgRef.current,
          start: "top 80%",
          end: "top 20%",
          scrub: 2,
          markers: false,
        },
      }
    );

    gsap.fromTo(
      buttonRef.current,
      { x: "90%", opacity: 0 }, // Start 30% to the left with opacity 0
      {
        x: "0%", // End at the original position
        opacity: 1, // Fully visible
        duration: 1.5, // Duration of the animation
        ease: "power3.out", // Smooth easing
        scrollTrigger: {
          trigger: buttonRef.current,
          start: "top 90%", // Trigger animation when element is 70% from the top
          end: "top 80%", // End when the top of the button is 50% from the top of the viewport
          scrub: 2, // Smooth animation synced with scroll
        },
      }
    );

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: textRef.current,
        start: "top 90%", // Start when the top of the section is 80% from the top of the viewport
        end: "top 20%", // End when the top of the section is 20% from the top of the viewport
        scrub: 1,
      },
    });

    tl.fromTo(
      titleRef.current,
      { scale: 2, opacity: 0 },
      { scale: 1, opacity: 1, duration: 1.2, ease: "power3.out" }
    )
      .fromTo(
        paragraphRef.current,
        { scale: 2, opacity: 0 },
        { scale: 1, opacity: 1, duration: 1.2, ease: "power3.out" },
        "-=0.6" // Overlap the animation with the previous by 0.6 seconds
      )
      .fromTo(
        imageRef.current,
        { scale: 2, opacity: 0 },
        { scale: 1, opacity: 1, duration: 1.2, ease: "power3.out" },
        "-=0.6" // Overlap the animation with the previous by 0.6 seconds
      );
  }, []);
  return (
    <section className="section-2">
      <video
        loop
        autoPlay={true}
        playsInline
        muted
        className="section-2-video align-bottom"
      >
        <source src="/section2bgvideo.mp4" type="video/mp4" />
      </video>
      <div className="sectin2-div">
        <div className="heading-div">
          <h3>Make your Own Jewellery</h3>
        </div>
        <br />
        <Container>
          <Row>
            <Col xs={0} sm={12} lg={6} md={6}>
              <div className="main-img1">
                <video
                  loop
                  autoPlay={true}
                  playsInline
                  muted
                  className="full-width-video align-bottom"
                  style={{ height: "100%", objectFit: "cover", width: "100%" }}
                >
                  <source src="/ladynecklaisvideo.mp4" type="video/mp4" />
                </video>
              </div>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <div className="text-div">
                <h4>Lets see how you can make your own</h4>
                <p>
                  If you've ever seen a piece of Jewellery in an old photo or
                  online that you wished you could own, now you can make it a
                  reality. Simply send us the image, and we'll craft the
                  Jewellery exactly to your liking.{" "}
                </p>
                <p>
                  Whether it's a vintage design, a modern piece, or something
                  entirely unique, we'll bring your vision to life, ensuring it
                  fits your style and needs perfectly.
                </p>
                <p>
                  With our custom service, you can wear the Jewellery you've
                  always dreamed of, tailored just for you.
                </p>
              </div>
              <div className="deskPicture-section-2">
                <Row className="g-2">
                  <Col xs={6} sm={6} lg={6}>
                    <img
                      src="/ladyphoto.png"
                      alt=""
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "fill",
                      }}
                    />
                  </Col>
                  <Col xs={6} sm={6} lg={6}>
                    <img
                      src="/ladyphoto2.png"
                      alt=""
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "fill",
                      }}
                    />
                  </Col>
                </Row>
              </div>
              <Row
                style={{ alignItems: "center", marginTop: "10px" }}
                className="text-div"
              >
                <Col xs={12} sm={5} md={6} lg={6} xl={5}>
                  <button
                    className="defult-btn-section-2 "
                    style={{ flex: 0.75, padding: "10px" }}
                    onClick={() =>
                      window.open(
                        "https://customize.luckystargold.com/",
                        "_blank"
                      )
                    }
                  >
                    Send Your Design
                  </button>
                </Col>
                <Col xs={12} sm={7} md={6} lg={6} xl={7}>
                  <div className="last-text">
                    Have a design in mind? Share it with us,
                    <br /> and we'll get back to you shortly!.
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}

export default Section2;
